import React from 'react'
import classNames from 'classnames'
import componentStyles from './CasesCard.module.scss'
import ShadowedContainer from '../ShadowedContainer/ShadowedContainer'
import Link from '../Link'

const CasesCard = ({ className, title, url }) => {
  const cardClasses = classNames(componentStyles.card, className)

  return (
    <article className={cardClasses}>
      <ShadowedContainer className={componentStyles.shadow}>
        <Link className={componentStyles.cardLink} to={url}>
          <div className={componentStyles.cardTitleWrapper}>
            <h2 className={componentStyles.cardTitle}>{title}</h2>
          </div>
        </Link>
      </ShadowedContainer>
    </article>
  )
}

export default CasesCard
