import React from 'react'
import classNames from 'classnames'
import { prepareHtml } from '../../utils/text-to-html-formatter'
import componentStyles from './CheckedText.module.scss'
import Check from '../../assets/images/check-icon.svg'

const CheckedText = ({ component, customClasses, text, title }) => {
  const Component = component || 'div'
  const preparedText = text ? prepareHtml(text) : ''
  const preparedTitle = title ? prepareHtml(title) : ''

  const containerClasses = classNames(
    componentStyles.container,
    customClasses && customClasses.containerClass
  )
  const iconClasses = classNames(
    componentStyles.icon,
    customClasses && customClasses.iconClass
  )
  const textClasses = classNames(
    componentStyles.text,
    customClasses && customClasses.textClass
  )
  const titleClasses = classNames(
    componentStyles.title,
    customClasses && customClasses.titleClass
  )

  return (
    <Component className={containerClasses}>
      <Check className={iconClasses} />
      <h4
        className={titleClasses}
        dangerouslySetInnerHTML={{ __html: preparedTitle }}
      />
      <p
        className={textClasses}
        dangerouslySetInnerHTML={{ __html: preparedText }}
      />
    </Component>
  )
}

export default CheckedText
