import React from 'react'
import classNames from 'classnames'
import camelCase from 'lodash-es/camelCase'
import { prepareHtml } from '../../utils/text-to-html-formatter'
import componentStyles from './Annotation.module.scss'

const Annotation = ({ borderSide, className, text }) => {
  const containerClass = classNames(
    componentStyles.annotation,
    componentStyles[camelCase(`annotation border ${borderSide || 'left'}`)],
    className)
  const content = prepareHtml(text)

  return (
    <div className={containerClass}>
      <span dangerouslySetInnerHTML={{ __html: content }}/>
    </div>
  )
}

export default Annotation

